<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card
          color="success"
          icon="mdi-clock"
          inline
          :title="$t('hack.quelle_heure_est_il')"
          class="px-5 py-3 mb-5"
        >
          <v-form>
            <div class="d-flex flex-row">
              <div class="mr-4">
                <h3>{{ $t('hack.date') }}</h3>
                <v-date-picker
                  v-model="pickerDate"
                  :locale="$t('locale.lang')"
                />
              </div>
              <div>
                <h3>{{ $t('hack.heure') }}</h3>
                <v-time-picker
                  v-model="pickerTime"
                  format="24hr"
                />
              </div>
            </div>
            <v-row class="pt-4">
              <v-btn
                block
                class="primary"
                @click="activerDelta"
              >
                <v-icon class="pr-3">
                  mdi-sort-clock-descending-outline
                </v-icon>
                {{ $t('hack.saut_dans_le_futur') }}
              </v-btn>
              <v-btn
                block
                class="orange"
                @click="annulerDelta"
              >
                <v-icon class="pr-3">
                  mdi-sort-clock-ascending-outline
                </v-icon>
                {{ $t('hack.saut_dans_le_present') }}
              </v-btn>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
      <v-col>
        <base-material-card
          color="success"
          icon="mdi-cog"
          inline
          title="Configuration"
          class="px-5 py-3 mb-5"
        >
          <h3> {{ $t('hack.heure_reelle') }}</h3>
          <p>
            {{ heureRéelle }}
          </p>
          <h3>{{ $t('hack.heure_simulee') }}</h3>
          <p>
            {{ heureSimulée }}
          </p>
        </base-material-card>
        <base-material-card
          color="success"
          icon="mdi-cog"
          inline
          title="Reconnexion"
          class="px-5 py-3 mb-5"
        >
          <v-text-field v-model="connexion_login" />
          <v-btn
            class="primary"
            @click="reconnecter"
          >
            {{ $t('hack.reconnecter') }}
          </v-btn>
        </base-material-card>
        <base-material-card
          color="success"
          icon="mdi-cog"
          inline
          title="Paramètres"
          class="px-5 py-3 mb-5"
        >
          <v-switch
            v-model="switchCacherInfoPageSecours.valeur"
            label="Cacher info page secours"
            @click="modifierParametre(switchCacherInfoPageSecours.id, 'cacherInfoPageSecours', switchCacherInfoPageSecours.valeur)"
          ></v-switch>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import horlogeService from '@/services/horlogeService.js'
  import toucanService from '@/services/toucanService.js'

  export default {
    data() {
      return {
        now: null,
        pickerDate: horlogeService
          .maintenant()
          .toISOString()
          .substring(0, 10),
        pickerTime: horlogeService.maintenant().toLocaleTimeString(),
        heureRéelle: null,
        heureSimulée: null,
        connexion_login: '',
        intervalTic: null,
        switchCacherInfoPageSecours: { valeur: null },
      }
    },

    mounted() {
      this.intervalTic = setInterval(this.tic, 1000)
      this.getParametres()
    },
    created() {
      this.now = new Date()
    },
    beforeDestroy() {
      clearInterval(this.intervalTic)
    },
    methods: {
      tic() {
        this.now = new Date()
        this.heureRéelle = this.now
        this.heureSimulée = horlogeService.maintenant()
      },
      activerDelta() {
        const str = `${this.pickerDate}T${this.pickerTime}`
        const d = new Date(str)
        horlogeService.setDelta(d - new Date().getTime())
      },
      annulerDelta() {
        horlogeService.setDelta(0)
      },
      reconnecter() {
        restApiService
          .get('/api/webUser/authoriser/' + this.connexion_login)
          .then(response => {
            const token = response.data.id_token
            this.$store.commit('SET_JWTTOKEN', token)
            restApiService
              .get('/api/webUser/infoUsager')
              .then(response => {
                this.$store.commit('SET_EVENEMENT_ACTIF', null)
                this.$store.commit('SET_COMPTECLIENT', null)

                toucanService.connexion(response.data)
              })
              .catch(erreur => {
                alert(erreur)
              })
          })
          .catch(erreur => alert(erreur))
      },
      modifierParametre(id, cle, valeur) {
        const parametre = {
          id: id,
          cle: cle,
          valeur: valeur,
        }
        if (parametre.id) {
          restApiService
            .put('/api/cnx-configurations', parametre)
            .then(response => {
              console.log(response)
            })
            .catch(erreur => alert(erreur))
        } else {
          restApiService
            .post('/api/cnx-configurations', parametre)
            .then(response => {
              console.log(response)
            })
            .catch(erreur => alert(erreur))
        }
      },
      getParametres() {
        restApiService
          .get('/api/cnx-configurations/cle/cacherInfoPageSecours')
          .then(response => {
            if (response.data) {
              console.log(response.data)
              this.switchCacherInfoPageSecours = response.data
            }
          })
          .catch(erreur => {

          })
      },
    },
  }
</script>
